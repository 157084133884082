@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

* {
  font-family: 'Gothic A1', sans-serif;
}

body {
  background-color: #f6e7c6;
  font-size: 18px;
}

.App {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

/* #header img{
  background-image: url(./images/hjd.png);
  max-height: 100%;
  min-height: 50%;
  max-width: 100%;
  min-width:50%;
  margin-top: 55px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
} */

#header{
  background-image: url(./images/hjd_big.png);
  /* height: 350px;
  min-height: 50%;
  width: 1200px;
  min-width:50%;
  margin-top: 55px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%); */
  /* background: center bottom; */
  /* background: url(http://snowdebts.com/images/banner-thin.png); */
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  height: 250px; width: 100%;
  max-width: 1600px;
  background-size: 100%;
  margin-top:75px;
}

a {
    color: black;
}

p {
  margin: 0 0 1em 0;
}

button {
  padding: 16px;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

h1 {
  text-align: center;
  font-size: 3.5em;
  margin: 0 0 .6em 0;
  font-weight: 300;
  color: rgb(12, 68, 16);
  font-family: 'Noto Serif', serif;
}

h2 {
    text-align: center;
    font-size: 2.5em;
    margin: 1 0 .6em .6em;
    font-weight: 300;
    color: rgb(12, 68, 16);
    font-family: 'Noto Serif', serif;
}

.carousel-header {
    text-align: center;
    font-size: 3em;
    margin: .5em 0;
    font-weight: 300;
    color: rgb(12, 68, 16);
    font-family: 'Noto Serif', serif;
}

.carousel-header-top {
    text-align: center;
    font-size: 3em;
    margin: .25em 0em .5em 0;
    font-weight: 300;
    color: rgb(12, 68, 16);
    font-family: 'Noto Serif', serif;
}

h3, h4 {
  margin-bottom: 0;
  margin: 0 0 1em 0;
}

nav {
  border-bottom: 4px solid rgb(12, 68, 16);
  text-align: center;
  color: rgb(12, 68, 16);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #f6e7c6;
  padding-bottom: 26px;
  padding-top: 5px;
  z-index: 9999;
  font-size: 1.25em;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

}

nav li {
  display: inline-block;
  padding-top: 16px;
}

nav a {
  font-family: 'Noto Serif', serif;
  text-decoration: none;
  color: rgb(12, 68, 16);
  font-weight: bold;
  padding: 16px 10px;
  position: relative;
  margin: 0px 20px;

}

nav a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(12, 68, 16);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

nav a:hover:after {
  transform: scaleX(1);
  transform-origin: center;
  align-self: center;
}

banner {
  padding-top: 30px;
  background: #f6e7c6;
}

#page-body {
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 0em;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.main-text {
  margin: 1em 0;
  overflow: hidden;
}

.table-wrapper {
  margin: 15px 0 0 0;
  text-align: center;
}

.image-gallery-svg {
  color:#f6e7c6;
}

.image-gallery-svg :hover {
  color: rgb(12, 68, 16);
}

.row {
  margin-top:40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: 100%;
  padding-left:8px;
  padding-right:8px;
  background: no-repeat center top;
  padding-top: 85px;
}

.column.community {
  background-image: url('./images/community.png');
  background-size: 75px 75px;
}

.column.mission {
  background-image: url('./images/present.png');
  background-size: 75px 75px;
}

.column.impact {
  background-image: url('./images/impact.png');
  background-size: 75px 75px;
}

.column.phone {
  background-image: url('./images/phone.png');
  background-size: 75px 75px;
}

.column.email {
  background-image: url('./images/email.png');
  background-size: 75px 75px;
}

.column.home {
  background-image: url('./images/home.png');
  background-size: 75px 75px;
}

.footer {
  color:black;
  max-width: 70%;
  margin: auto;
  text-align: center;
  padding-top:20px;
  padding-bottom:20px;
}

.social-container {
  background: #eee;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.facebook {
  color:rgb(12, 68, 16);
}

a.twitter {
  color: rgb(12, 68, 16);
}

a.instagram {
  color:rgb(12, 68, 16);
}

hr {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: rgb(12, 68, 16);
}

#page-body hr  {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:2em;
}

#main-image  {
  float: left;    
  max-width: 65%;
  max-height: 65%;
  margin: 0 2em 0.5em 0;
}

.rcorners {
  border-radius: 5%;
}

.qr {
  max-width: 30%;
  max-height: 30%;
  margin: 1em 1em;
}

/* .qr.cashapp {
  width: 40%;
  height: 40%;
} */

.qrcodes {
  display:flex;
  justify-content:center;
}

@media screen and (max-width: 1480px) {
  #header {
    background-image: url(./images/hjd_bedium.png);
    height: 200px;
  }

    .carousel-header {
        text-align: center;
        font-size: 2.8em;
        margin: .5em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

    .carousel-header-top {
        text-align: center;
        font-size: 2.8em;
        margin: .25em 0 .5em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }
}

@media screen and (max-width: 1200px) {
  #header {
    background-image: url(./images/hjd_medium.png);
    height: 180px;
    margin-top:63px;
  }

  h1 {
    font-size: 2.4em;
  }

  #main-image {
    max-width: 500px;
    height: auto;
  }

  .column.community {
    background-size: 60px 60px;
  }
  
  .column.mission {
    background-size: 60px 60px;
  }
  
  .column.impact {
    background-size: 60px 60px;
  }
  
  .column.phone {
    background-size: 60px 60px;
  }
  
  .column.email {
    background-size: 60px 60px;
  }
  .column.home {
    background-size: 60px 60px;
  }

  nav {
    font-size: 1.15em;
    padding-bottom: 20px;
  }

  .box {
    max-height: 300px;
  }
}

@media screen and (max-width: 1080px) {
  #header {
    background-image: url(./images/hjd_medium.png);
    height: 150px;
    margin-top:63px;
  }

  .carousel-header {
    text-align: center;
    font-size: 2.3em;
    margin: .5em 0;
    font-weight: 300;
    color: rgb(12, 68, 16);
    font-family: 'Noto Serif', serif;
}

.carousel-header-top {
    text-align: center;
    font-size: 2.3em;
    margin: .25em 0 .5em 0;
    font-weight: 300;
    color: rgb(12, 68, 16);
    font-family: 'Noto Serif', serif;
}
}

@media screen and (max-width: 875px) {

    .carousel-header {
        text-align: center;
        font-size: 1.8em;
        margin: .5em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

    .carousel-header-top {
        text-align: center;
        font-size: 1.8em;
        margin: .25em 0 .4em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

  #header {
    background-image: url(./images/hjd_medium.png);
    height: 120px;
    margin-top:58px;
  }

  h1 {
    font-size: 2em;
  }

  #main-image {
    max-width: 385px;
    height: auto;
  }

  .column.community {
    background-size: 60px 60px;
  }
  
  .column.mission {
    background-size: 60px 60px;
  }
  
  .column.impact {
    background-size: 60px 60px;
  }

  .column.phone {
    background-size: 60px 60px;
  }
  
  .column.email {
    background-size: 60px 60px;
  }
  .column.home {
    background-size: 60px 60px;
  }

  nav {
    font-size: 1.08em;
    padding-bottom: 15px;
  }

  #page-body {
    padding-top:8px;
  }
}

@media screen and (max-width: 700px) {
  #header {
    background-image: url(./images/hjd_small.png);
    height: 125px;
    margin-top:58px;
  }
}

@media screen and (max-width: 600px) {

    .carousel-header {
        text-align: center;
        font-size: 1.3em;
        margin: .5em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

    .carousel-header-top {
        text-align: center;
        font-size: 1.3em;
        margin: .25em 0 .4em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

  #header {
    background-image: url(./images/hjd_micro.png);
    height: 170px;
    margin-top:53px;
  }

  h1 {
    font-size: 1.9em;
  }

  .image-gallery-icon {
    size:50%;
  }

  #main-image {
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    right: 0;
    top: 0;
    padding-bottom:10px;
    -o-object-fit: contain;
    object-fit: contain;
    /* padding: 0px 20px; */
  }

  .row {
    margin-top:0px;
    display: grid;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .column {
    display: inline-block;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    height: 100%;
    padding-left:8px;
    padding-right:8px;
    background: no-repeat center top;
    padding-top: 85px;
    padding-bottom: 30px;
  }
  .column h3 {
    display: grid;
    margin-bottom: 20px;
  }
  
  nav {
    font-size: .85em;
    padding-bottom: 14px;
  }

  nav a {
    margin: 8px;
  }
  
  .image-gallery-icon {
    size:30%;
  }
  .qrcodes {
    display:inline;
    justify-content:center;
  }
  .qr {
    max-width:90%;
    max-height:90%;
  }
  .qr.cashapp {
    width:90%;
    height:90%;
  }
}

@media screen and (max-width: 500px) {
  #header {
    background-image: url(./images/hjd_micro.png);
    height: 140px;
    margin-top:55px;
  }

  h1 {
    font-size: 1.80em;
  }

  nav {
    font-size: .80em;
    padding-bottom: 15px;
  }

  nav a {
    margin: 6.5px;
  }
  
  .image-gallery-icon {
    size:25%;
  }
}

@media screen and (max-width: 400px) {
  #header {
    background-image: url(./images/hjd_micro.png);
    height:110px;
    margin-top:53px;
  }

  h1 {
    font-size: 1.7em;
  }

  nav {
    font-size: .65em;
    padding-bottom: 16px;
  }

  nav a {
    margin: 5px;
  }

  .image-gallery-icon {
    size:25%;
  }

    .carousel-header {
        text-align: center;
        font-size: 1em;
        margin: .5em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

    .carousel-header-top {
        text-align: center;
        font-size: 1em;
        margin: .25em 0 .4em 0;
        font-weight: 300;
        color: rgb(12, 68, 16);
        font-family: 'Noto Serif', serif;
    }

}
